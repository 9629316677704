<template>
  <div>

    <DefaultModal
      :title="$t('move_confirm_title')"
      ref="moveModal"
      @submit="callMoveMethod"
      @modal-closed="resetConfirmModal()"
      submit-button-text="move"
      :loading="modalLoading"
      :saveButtonDisabled="modalSaveButtonDisabled"
      :alert="modalAlert"
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
           <p class="pre-line"><span class="font-weight-bold">From:</span> {{destination.from}}</p>
          </v-row>
          <v-row>
            <p><span class="font-weight-bold">To:</span> {{destination.to}}</p>
          </v-row>
          <v-row v-if="modalAlert" class="mt-4">
            <v-alert
              dense
              outlined
              :type="modalAlert.type"
            >
              {{modalAlert.message}}
            </v-alert>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>

    <v-breadcrumbs
      v-if="items"
      :items="formattedBreadcrumbs"
      class="breadcrumbs-custom"
    >

      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>

      <template v-slot:item="{ item }">
        <v-tooltip open-delay="1000" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-breadcrumbs-item
              v-bind="attrs"
              v-on="on"
              :to="item.to"
              :href="item.href"
              :link="item.link"
              :disabled="item.disabled"
              :exact="item.exact"
              :ref="item.ref"
              :style="maxWidthItem ? maxWidthItemClass : false"
              @click="onBreadcrumbItemClick(item)"
              @drop="onDrop"
              @dragover="dragOver($event, item)"
              @dragenter="dragEnter($event, item)"
              @dragleave="dragLeave()"
              @dragend="dragEnd()"
              :class="{'drag-over': item.to != undefined && item.to.params.parentId == droppedNodeId && droppedNodeId != undefined}"
            >
              <v-icon
                v-if="item.prependIcon"
                v-text="item.prependIcon"
                :color="item.iconColor || 'defaultIconColor'"
              >
              </v-icon>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
          <span>{{ item.tooltipText ? item.tooltipText : item.text }}</span>
        </v-tooltip>
      </template>
    </v-breadcrumbs>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      nudge-bottom="8"
      nudge-left="8"
    >
      <v-list class="menu-custom">
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          link
          :href="item.href"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon color="defaultIconColor">mdi-folder</v-icon>
          </v-list-item-icon>

          <v-tooltip open-delay="1000" bottom>
            <template v-slot:activator="{ on: tooltip, attrs }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="$t(item.text)"
                  v-bind="attrs"
                  v-on="{ ...tooltip }"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>

.drag-over {
    border: 2px dashed var(--v-primary-base);
    background-color: var(--v-primary-lighten5);
  }

</style>

<script>

import { mapActions } from 'vuex'
import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import DefaultModal from '@/components/DefaultModal.vue'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: "ContentBreadcrumbs",

  components: {
    DefaultModal,
  },

  data: () => ({
    showMenu: false,
    x: 0 , y: 0,
    truncateFrom: 5,
    droppedNodeId: 0,
    sourceNodeList: [],
    destinationNode: null,
    destination: {},
    modalLoading: true,
    modalSaveButtonDisabled: true,
    modalAlert: null,
  }),

  props: {
    items: {
      type: Array,
      required: true
    },
    maxWidthItem: {
      type: String
    },
    searchResult: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    formattedBreadcrumbs() {

      let formatted = this.items

      if (this.items.length >= this.truncateFrom) {
        formatted = [
          ...this.items.slice(0, this.numberItemsToShowStart),
          {
            text: "...",
            ripple: false,
            tooltipText: "Show path",
            ref: "truncatedNode",
          },
          ...this.items.slice(-this.numberItemsToShowEnd),
        ];
      }

      if(this.searchResult) {
        formatted.push({ text: "Search result", ripple: false });
      }

      return formatted
    },

    menuItems() {
      if (this.items.length < this.truncateFrom) return [];

      return [
        ...this.items.slice(
          this.numberItemsToShowStart,
          -this.numberItemsToShowEnd
        ),
      ];
    },

    numberItemsToShowStart() {
      return this.$vuetify.breakpoint.name == "xs" ? 1 : 2
    },

    numberItemsToShowEnd() {
      return this.searchResult ? 1 : 2
    },

    maxWidthItemClass () {
      return `max-width: ${this.maxWidthItem} !important;`
    }
  },

  updated() {
    if (this.$refs.truncatedNode) {
      let truncadedElement = this.$refs.truncatedNode.$el;
      truncadedElement.addEventListener('click', this.showPathMenu);
      truncadedElement.style.cursor = "pointer";
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.MOVE,
      ContentActions.MOVE_ENTRIES
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_COPY_MOVE_POSSIBILITY,
    ]),

    showPathMenu (evt) {
      evt.preventDefault();
      if(evt.target.nodeName == "DIV") {
        this.showMenu = false
        this.x = evt.target.getBoundingClientRect().left
        this.y = evt.target.getBoundingClientRect().bottom
        this.$nextTick(() => {
          this.showMenu = true
        })
      }
    },

    dragOver(event, item) {
      parent.parent.droppedNodeId = item.to.params.parentId
      this.droppedNodeId = item.to.params.parentId
      event.preventDefault()
    },

    dragEnter(event, item) {
      this.dragOver(event, item)
    },

    dragEnd() {
      parent.dragInternal = false
      parent.parent.droppedNodeId = 0
      this.droppedNodeId = 0
    },

    dragLeave() {
      parent.parent.droppedNodeId = 0
      this.droppedNodeId = 0
    },

    onDrop(event) {
      var internal = parent.dragInternal
      var droppedNodeId = parent.parent.droppedNodeId
      var valid = true;
      parent.draggedList.forEach(item => {
        if (item.node_id == droppedNodeId) {
          valid = false
        }
      })
      if (valid && internal) {
        this.sourceNodeList = parent.draggedList
        this.destinationNode =  { 'node_id': droppedNodeId, 'entry_fs_id': event.target.innerText }
        this.confirmModal()
      }
      parent.dragInternal = false
      parent.parent.droppedNodeId = 0
      this.droppedNodeId = 0
    },

    confirmModal() {
      const sourceNodeList = this.sourceNodeList
      const destinationNode = this.destinationNode

      this.destination.from = '\n' + sourceNodeList.map(i => i.entry_fs_id).join(',\n');

      this.destination.to = destinationNode.node_parent === null ? `/${destinationNode.name}` : destinationNode.entry_fs_id;

      this.resetConfirmModal()
      this.$refs.moveModal.openModal()

      let nodesIds = sourceNodeList.map(item => item.node_id)

      const data = {"destination_id": destinationNode.node_id, "action_key": "move", "nodes_ids": nodesIds}

      var canContinue = true;

      this[EntryActions.CHECK_COPY_MOVE_POSSIBILITY](data)
        .then(responses => {
          this.modalLoading = false;

          responses.forEach(res => {
            if (res.node_name_already_exists) {
              canContinue = false;
              this.modalAlert = { type: "error", message: this.$t('move_confirm_alert') }
            }
          })
        })
        .catch(() => this.loading = false)
        .finally(() => this.modalSaveButtonDisabled = !canContinue)
    },

    resetConfirmModal() {
      this.modalLoading = true;
      this.modalSaveButtonDisabled = true;
      this.modalAlert = null;
    },

    callMoveMethod() {
      this.$refs.moveModal.closeModal()

      let nodesIds = this.sourceNodeList.map(item => item.node_id)
      let names = this.sourceNodeList.map(item => item.name)

      let name = names[0]

      if (names.length > 1) {
        name = name + ' (+' + (names.length -1) + ')'
      }

      let params = {'entries': nodesIds, 'destination_id': this.destinationNode.node_id, 'name': name}

      this[ContentActions.MOVE_ENTRIES](params)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(`move_successful`))
          this.$emit(`move-success`)
        })
        .catch((error) => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`$move_failed`))
          this.$emit(`move-error`, error)
        })
    },

    onBreadcrumbItemClick(item) {
      if (item.event == undefined) {
        return
      }

      this.$emit(item.event, item)
    }
  }
};
</script>

<style scoped lang="scss">

.breadcrumbs-custom {
  padding: 0 !important;
  margin: 0 !important;
  overflow : hidden;

  & li {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    border-radius: 4px;
    padding: 4px 8px !important;
    line-height: 1.5rem;

    & ::v-deep a, & ::v-deep div {
      display: inline !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    & .v-icon {
      font-size: 1.5rem !important;
      &:not(.mdi-chevron-right) {
        top: -1px;
      }
    }

    &:not(:last-of-type):not(.v-breadcrumbs__divider):hover {
      background-color: rgba(0, 0, 0, .05);
      transform: translateY(1px);
    }

    &:not(:first-of-type):last-of-type {
      font-weight: 400 !important;
    }
  }

  & ::v-deep .v-breadcrumbs__divider {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.menu-custom {
  min-width: 250px;

  & .v-list-item__icon {
    margin-right: 20px !important;
  }
  
  & .v-list-item__content {
    font-weight: 500 !important;
    max-width: 250px;
    padding-right: 10px;
  }
}
</style>
